.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nav {
  background-color: white;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 40px;
  width: 40px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  font-family: "Avenir Next" ;
  font-size: 20.0px;
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: #333;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.section {
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.section-content h1 {
  font-family: "Avenir Next" ;
  font-weight: 700 ;
  display: table;
  color: #373737ff ;
  font-size: 22.0px ;
  line-height: null.0px ;
  opacity: 1 ;
  text-align: center ;
  font-kerning: null ;
}

.section-content p {
  font-family: "Avenir Next" ;
  color: #525252ff ;
  font-size: 20.0px ;
  line-height: 38.0px ;
  opacity: 1 ;
  text-align: justify ;
  font-kerning: null ;
}

.section-content a {
  font-family: "Avenir Next" ;
  font-weight: 700 ;
  color: rgb(111, 105, 165) ;
  font-size: 22.0px ;
  line-height: 38.0px ;
  opacity: 1 ;
  text-align: justify ;
  font-kerning: null ;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clickable {
	cursor: pointer;
}

/* sketch css (modified)*/

.sk-asset.sk-COMING-SOON {
	width: 170px;
	height: 40px;
}

.sk-asset.sk-COMING-SOON {
	font-family: "Avenir Next" ;
	font-weight: 700 ;
	color: #ffffffff ;
	font-size: 22.0px ;
	line-height: 40.0px ;
	opacity: 1 ;
	text-align: left ;
	font-kerning: null ;
}

.sk-asset.sk-COMING-SOON .span1 {
	font-family: "Avenir Next" ;
	font-weight: 700 ;
	display: table-cell;
	color: #FFFFFF ;
	font-size: 22.0px ;
}

.sk-asset.sk-Coming-Soon {
	width: 224px;
	height: 64px;
}

.sk-asset.sk-Rectangle {
	background-image: url("images/Rectangle-F75061C0-4AFB-4675-B50C-300FF42448A9.png");
	width: 100%;
	height: 64px;
}

.sk-asset {
	background-repeat: no-repeat;
}

.sk-asset.title {
	display: table;
	line-height: null.0px ;
	opacity: 1 ;
	text-align: center ;
	font-kerning: null ;
}

.sk-asset.title .span1 {
	font-family: "Avenir Next" ;
	font-weight: 700 ;
	display: table-cell;
	vertical-align: top;
	color: #373737 ;
	font-size: 63.0px ;
}

.sk-asset.subtitle {
	font-family: "Avenir Next" ;
	font-weight: 700 ;
	color: #525252ff ;
	font-size: 22.0px ;
	line-height: 38.0px ;
	opacity: 1 ;
	text-align: center ;
	font-kerning: null ;
}

.sk-asset.subtitle .span1 {
	font-family: "Avenir Next" ;
	font-weight: 700 ;
	color: #525252ff ;
	font-size: 22.0px ;
}

.sk-asset.description {
	font-family: "Avenir Next" ;
	color: #525252ff ;
	font-size: 22.0px ;
	line-height: 38.0px ;
	opacity: 1 ;
	text-align: center ;
	font-kerning: null ;
}

.sk-asset.sk-Social-Media-Icons {
  margin-top: 20pt;
  display: flex;
	width: 100%;
  max-width: 314px;
	height: auto;
  justify-content: space-between;
  align-items: center;
}

.sk-asset.sk-floating_Instagram_normal {
	background-image: url("images/floating_Instagram_normal-48B1B0BF-4335-4894-B4CE-18F084164FF7.png");
	width: 40px;
	height: 40px;
}

.sk-asset.sk-Oval {
	background-image: url("images/Oval-8-A9D2F7B4-0B74-452A-8469-E6FAD62CA531.png");
	width: 40px;
	height: 40px;
}

.sk-asset.sk-Twitterlogo {
	background-image: url("images/Twitterlogo-6253935B-1E67-40B0-9C8B-8C97F1DDA8F2.png");
	width: 27px;
	height: 22px;
  left: 7px;
  top: 10px;
  position: absolute;
}

.sk-asset.sk-floating_Twitter_normal {
	width: 40px;
	height: 40px;
  position: relative;
}

.sk-asset.sk-floating_LinkedIn_normal {
	background-image: url("images/floating_LinkedIn_normal-37C05694-D2F5-4498-BB18-462E62B08244.png");
	width: 40px;
	height: 40px;
}

.sk-asset.sk-facebook {
	background-image: url("images/facebook-06B98E44-7569-4BB8-A623-8083BAB5E576.png");
	width: 44px;
	height: 44px;
}
